<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">7</div>
        <div class="fz20">
          骨质疏松风险评估报告
          <div>
            <span class="smallTitle1">您高骨质疏松的发病风险为：</span>
            <span class="smallTitle2">{{
              listData.extend.osteoporosis || ""
            }}</span>
          </div>
        </div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div class="chart" style="margin-bottom: 30px">
          <img src="@/assets/guzhi.png" />
        </div>
        <div class="titleStyle">一、预防建议</div>
        <div>
          <p style="line-height: 30px">
            1、饮食均衡，限制食盐摄入。中国居民膳食指南建议，每人每天食盐的摄入量为6g。
          </p>
          <p style="line-height: 30px">
            2、适量蛋白质摄入。一般推荐健康成年人每日摄入1.0克蛋白质/千克体重比较合适，个别老年人可达每日1.2-1.5克/千克体重。
          </p>
          <p style="line-height: 30px">
            3、充足的钙。成人每日钙摄入推荐量800毫克(元素钙量)，绝经后妇女和老年人每日钙摄入推荐量为1000毫克。含钙高的食物如奶制品、鱼类、虾蟹、豆类、坚果类等。
          </p>
          <p style="line-height: 30px">
            4、丰富的维生素。维生素D的成年人推荐量为400单位(10微克/天)，老年人推荐量为400-800IU(10-20微克/天)。
          </p>
          <p style="line-height: 30px">
            5、避免不健康的生活方式。避免食盐摄入过量、吸烟、酗酒、饮用咖啡和过多饮用碳酸饮料等不利于骨质疏松预防的不良生活方式。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
export default {
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
