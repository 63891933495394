<template>
  <div id="index" ref="appRef">
    <div class="bg" style="overflow-y: auto">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="container container-report">
          <div class="container-title">
            <div class="container-back-box" @click="onBack">
              <span class="el-icon-arrow-left"></span>
              <span class="back">返回</span>
            </div>
            <div class="name">个人健康评估报告</div>
            <!-- <el-button size="small" @click="getPdfM">导出PDF</el-button>
            <el-button size="small" v-print="printObj">打印</el-button> -->
          </div>
          <!-- 主体内容 -->
          <div class="main" id="containerRef">
            <div class="healthInformation">
              <div class="healthInformation-title">
                <div class="healthInformation-number stepNumber">1</div>
                <div class="fz24">个人健康信息汇总报告</div>
              </div>
              <div class="healthInformation-table">
                <!-- 个人信息 -->
                <div style="margin-top: 20px"></div>
                <table class="tableStyle tCenter" style="width: 100%">
                  <tr class="tableTitle">
                    <td class="fz16">所属医院</td>
                    <td class="fz16">签约医师</td>
                    <td class="fz16">姓名</td>
                    <td class="fz16">性别</td>
                    <td class="fz16">年龄</td>
                    <td class="fz16">联系电话</td>
                    <td class="fz16">身份证号</td>
                    <td class="fz16">报告时间</td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fontColor">
                      {{
                        this.$route.query.hospitalName
                          ? handleString(this.$route.query.hospitalName)
                          : ""
                      }}
                    </td>
                    <td class="fontColor">
                      {{
                        userInfo.doctorName
                          ? handleString(userInfo.doctorName)
                          : ""
                      }}
                    </td>
                    <td class="fontColor">
                      {{ userInfo.name ? handleString(userInfo.name) : "" }}
                    </td>
                    <!-- <td class="fontColor">***</td> -->
                    <td class="fontColor">
                      {{ userInfo.gender ? userInfo.gender : "" }}
                    </td>
                    <td class="fontColor">
                      {{ userInfo.age ? userInfo.age : "" }}
                    </td>
                    <td class="fontColor">
                      {{ userInfo.phone ? handleString(userInfo.phone) : "" }}
                    </td>
                    <!-- <td class="fontColor">***********</td> -->
                    <td class="fontColor">
                      {{
                        userInfo.idCard ? handleString(userInfo.idCard, 6) : ""
                      }}
                    </td>
                    <!-- <td class="fontColor">****************</td> -->
                    <td class="fontColor">
                      {{
                        userInfo.reportTime
                          ? parseTime(userInfo.reportTime)
                          : ""
                      }}
                    </td>
                  </tr>
                </table>

                <!-- 一、疾病风险评估结果 -->
                <div class="titleStyle">一、疾病风险评估结果</div>
                <table class="tableStyle tCenter" style="width: 100%">
                  <tr class="tableTitle">
                    <td class="fz16">疾病种类</td>
                    <td
                      style="font-size: 16px"
                      v-for="(item, index) in typeList"
                      :key="index"
                    >
                      {{ item.type }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">评估结果</td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in resultList"
                      :key="index"
                    >
                      {{ item.result }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">疾病种类</td>
                    <td v-for="(item, index) in typeList2" :key="index">
                      {{ item.type }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">评估结果</td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in resultList2"
                      :key="index"
                    >
                      {{ item.result }}
                    </td>
                  </tr>
                </table>

                <!-- 二、重要指标检查结果 -->
                <div class="titleStyle">二、重要指标检查结果</div>
                <table class="tableStyle tCenter" style="width: 100%">
                  <tr class="tableTitle">
                    <td class="fz16">检查指标</td>
                    <td v-for="(item, index) in importantList" :key="index">
                      {{ item.quota }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">
                      本次检查
                      <!-- {{ parseTime(importantList[0].thisMonitoringTime) || "" }} -->
                    </td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in importantList"
                      :key="index"
                    >
                      {{ item.thisMonitoringValue }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">
                      上次检查
                      <!-- {{ parseTime(importantList[0].upMonitoringTime) || "" }} -->
                    </td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in importantList"
                      :key="index"
                    >
                      {{ item.upMonitoringValue }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">参考值</td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in importantList"
                      :key="index"
                    >
                      {{ item.reference }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">检查指标</td>
                    <td v-for="(item, index) in importantList2" :key="index">
                      {{ item.quota }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">
                      本次检查
                      <!-- {{ parseTime(importantList[0].thisMonitoringTime) || "" }} -->
                    </td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in importantList2"
                      :key="index"
                    >
                      {{ item.thisMonitoringValue }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">
                      上次检查
                      <!-- {{ parseTime(importantList[0].upMonitoringTime) || "" }} -->
                    </td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in importantList2"
                      :key="index"
                    >
                      {{ item.upMonitoringValue }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">参考值</td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in importantList2"
                      :key="index"
                    >
                      {{ item.reference }}
                    </td>
                  </tr>
                </table>

                <div style="page-break-after: always"></div>
                <!-- 三、生活方式评估结果 -->
                <div class="titleStyle">三、生活方式评估结果</div>
                <table class="tableStyle tCenter">
                  <tr class="tableTitle">
                    <td class="fz16">检查指标</td>
                    <td v-for="(item, index) in LifeQuotaList" :key="index">
                      {{ item.quota }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">
                      本次检查 {{ parseTime(lifeThisMonitoringTime) }}
                    </td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in lifeThisMonitoringValue"
                      :key="index"
                    >
                      {{ item.thisMonitoringValue }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">
                      上次检查 {{ parseTime(lifeUpMonitoringTime) }}
                    </td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in lifeUpMonitoringValue"
                      :key="index"
                    >
                      {{ item.upMonitoringValue }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">参考值</td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in lifeReference"
                      :key="index"
                    >
                      {{ item.reference }}
                    </td>
                  </tr>
                </table>
                <!-- 四、个人疾病史 -->
                <div class="titleStyle">四、个人疾病史</div>
                <table class="tableStyle tCenter">
                  <tr class="tableTitle">
                    <td class="fz16">疾病名称</td>
                    <td v-for="(item, index) in nameList" :key="index">
                      {{ item.name }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">有无</td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in haveList"
                      :key="index"
                    >
                      <img
                        style="width: 17px; height: 17px"
                        v-if="item.have === true"
                        src="@/assets/bingo.png"
                      />
                      <span v-else>-</span>
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">疾病名称</td>
                    <td v-for="(item, index) in nameList2" :key="index">
                      {{ item.name }}
                    </td>
                  </tr>
                  <tr class="tableTitle">
                    <td class="fz16">有无</td>
                    <td
                      class="fontColor"
                      v-for="(item, index) in haveList"
                      :key="index"
                    >
                      <img
                        style="width: 17px; height: 17px"
                        v-if="item.have === true"
                        src="@/assets/bingo.png"
                      />
                      <span v-else>-</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- 强制分页 -->
            <div style="page-break-after: always"></div>
            <Diabetes :listData="diabetes" />
            <div style="page-break-after: always"></div>
            <LungCancer :listData="lungCancer" />
            <div style="page-break-after: always"></div>
            <CoronaryHeartDisease :listData="coronaryHeartDisease" />
            <div style="page-break-after: always"></div>
            <Fat :listData="fat" />
            <div style="page-break-after: always"></div>
            <Blood :listData="blood" />
            <div style="page-break-after: always"></div>
            <Osteoporosis :listData="osteoporosis" />
            <div style="page-break-after: always"></div>
            <BreastCancer :listData="breastCancer" />
            <div style="page-break-after: always"></div>
            <BloodFat :listData="bloodFat" />
            <div style="page-break-after: always"></div>
            <Stroke :listData="stroke" />
            <div style="page-break-after: always"></div>
            <GastricCarcinoma :listData="gastricCarcinoma" />
            <div style="page-break-after: always"></div>
            <ColorectalCancer :listData="colorectalCancer" />
            <div style="page-break-after: always"></div>
            <MetabolicComprehensive :listData="metabolicComprehensive" />
            <div style="page-break-after: always"></div>
            <DietaryHabits :listData="dietaryHabits" />
            <div style="page-break-after: always"></div>
            <Life :listData="life" />
            <div style="page-break-after: always"></div>
            <MotorBehavior :listData="motorBehavior" />
            <div style="page-break-after: always"></div>
            <PersonalizedMovement1 :listData="personalizedMovement1" />
            <div style="page-break-after: always"></div>
            <PersonalizedMovement2 />
            <div style="page-break-after: always"></div>
            <PersonalizedDiet1 />
            <div style="page-break-after: always"></div>
            <PersonalizedDiet2 />
            <div style="page-break-after: always"></div>
            <HealthAction :listData="healthAction" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleString } from "@/utils";
import htmlToPdf from "@/utils/htmlToPdf";
import Diabetes from "@/views/diseaseType/diabetes";
import LungCancer from "@/views/diseaseType/lungCancer";
import CoronaryHeartDisease from "@/views/diseaseType/coronaryHeartDisease";
import Fat from "@/views/diseaseType/fat";
import Blood from "@/views/diseaseType/blood";
import Osteoporosis from "@/views/diseaseType/osteoporosis";
import BreastCancer from "@/views/diseaseType/breastCancer";
import BloodFat from "@/views/diseaseType/bloodFat";
import Stroke from "@/views/diseaseType/stroke";
import GastricCarcinoma from "@/views/diseaseType/gastricCarcinoma";
import ColorectalCancer from "@/views/diseaseType/colorectalCancer";
import MetabolicComprehensive from "@/views/diseaseType/metabolicComprehensive";
import DietaryHabits from "@/views/diseaseType/dietaryHabits";
import Life from "@/views/diseaseType/life";
import MotorBehavior from "@/views/diseaseType/motorBehavior";
import PersonalizedMovement1 from "@/views/diseaseType/personalizedMovement1";
import PersonalizedMovement2 from "@/views/diseaseType/personalizedMovement2";
import PersonalizedDiet1 from "@/views/diseaseType/personalizedDiet1";
import PersonalizedDiet2 from "@/views/diseaseType/personalizedDiet2";
import HealthAction from "@/views/diseaseType/healthAction";
import { parseTime } from "@/utils/index";
import drawMixin from "@/utils/drawMixin";
import { particulars } from "@/api/particulars";
export default {
  mixins: [drawMixin],
  components: {
    Diabetes,
    LungCancer,
    CoronaryHeartDisease,
    Fat,
    Blood,
    Osteoporosis,
    BreastCancer,
    BloodFat,
    Stroke,
    GastricCarcinoma,
    ColorectalCancer,
    MetabolicComprehensive,
    DietaryHabits,
    Life,
    MotorBehavior,
    PersonalizedMovement1,
    PersonalizedMovement2,
    PersonalizedDiet1,
    PersonalizedDiet2,
    HealthAction,
  },
  data() {
    return {
      handleString,
      parseTime,
      loading: false,
      userInfo: {},
      typeList: [], // 疾病种类
      typeList2: [], // 疾病种类
      resultList: [], // 评估结果
      resultList2: [], // 评估结果
      quotaList: [],
      thisMonitoringTime: "",
      thisMonitoringValueList: [],
      upMonitoringTime: "",
      upMonitoringValueList: [],
      referenceList: [],
      LifeQuotaList: [],
      lifeThisMonitoringTime: "",
      lifeThisMonitoringValue: [],
      lifeUpMonitoringTime: "",
      lifeUpMonitoringValue: [],
      lifeReference: [],
      nameList: [],
      nameList2: [],
      haveList: [],
      importantList: [],
      importantList2: [],
      printObj: {
        // 打印配置
        id: "containerRef",
        popTitle: "",
        extraCss: "",
        extraHead: "",
      },
      /**
       * 疾病类型组件
       */
      diabetes: {},
      lungCancer: {},
      coronaryHeartDisease: {},
      fat: {},
      blood: {},
      osteoporosis: {},
      breastCancer: {},
      bloodFat: {},
      stroke: {},
      gastricCarcinoma: {},
      colorectalCancer: {},
      metabolicComprehensive: {},
      personalizedMovement1: {},
      motorBehavior: {},
      dietaryHabits: {},
      life: {},
      healthAction: {},
    };
  },
  created() {
    this.getUserInfo();
    this.getDiseaseDetail();
  },
  activated() {
    // this.loading = true;
    this.getUserInfo();
    this.getDiseaseDetail();
  },
  methods: {
    cancelLoading() {
      this.loading = false;
    },
    async getUserInfo() {
      const res = await particulars.getReportUserInfo(this.$route.query.userId);
      this.userInfo = res.data;
      const riskResultList = res.data.riskResultList;
      this.typeList = riskResultList.map((item) => {
        // 疾病种类
        return (item = {
          type: item.type,
        });
      });
      this.typeList2 = this.typeList.splice(
        Math.ceil(this.typeList.length / 2)
      );
      this.resultList = riskResultList.map((item) => {
        // 评估结果
        return (item = {
          result: item.result,
        });
      });
      this.resultList2 = this.resultList.splice(
        Math.ceil(this.resultList.length / 2)
      );

      this.importantList = riskResultList;
      this.importantList.forEach((item, index) => {
        if (item.type === "结直肠癌" || item.type === "代谢综合症") {
          this.importantList.splice(index, 2);
        }
      });
      this.importantList2 = this.importantList.splice(
        Math.ceil(this.importantList.length / 2)
      );
      const lifeResultList = res.data.lifeResultList; // 生活方式评估结果
      this.LifeQuotaList = lifeResultList.map((item) => {
        //检查指标
        return (item = {
          quota: item.quota,
        });
      });
      this.lifeThisMonitoringTime = lifeResultList[0]?.thisMonitoringTime ?? "";
      this.lifeThisMonitoringValue = lifeResultList.map((item) => {
        return (item = {
          thisMonitoringValue: item.thisMonitoringValue,
        });
      });
      this.lifeUpMonitoringTime = lifeResultList[0]?.upMonitoringTime ?? "";
      this.lifeUpMonitoringValue = lifeResultList.map((item) => {
        return (item = {
          upMonitoringValue: item.upMonitoringValue,
        });
      });
      this.lifeReference = lifeResultList.map((item) => {
        return (item = {
          reference: item.reference,
        });
      });
      // 个人疾病史
      const diseaseKeyPairList = res.data.diseaseKeyPairList;
      this.nameList = diseaseKeyPairList.map((item) => {
        return (item = {
          name: item.name,
        });
      });
      this.nameList2 = this.nameList.splice(
        Math.ceil(this.nameList.length / 2)
      );
      this.haveList = diseaseKeyPairList.map((item) => {
        return (item = {
          have: item.have,
        });
      });
      this.haveList2 = this.haveList.splice(
        Math.ceil(this.haveList.length / 2)
      );
    },
    async getDiseaseDetail() {
      const res = await particulars.getReport(this.$route.query.userId);
      this.loading = false;
      res.data.forEach((item) => {
        if (item) {
          switch (item.diseaseType) {
            case "DIABETES":
              this.diabetes = item;
              break;
            case "LUNG_CANCER":
              this.lungCancer = item;
              break;
            case "CORONARY_HEART_DISEASE":
              this.coronaryHeartDisease = item;
              break;
            case "OBESITY":
              this.fat = item;
              break;
            case "HIGH_BLOOD":
              this.blood = item;
              break;
            case "OSTEOPOROTIC_FRACTURE":
              this.osteoporosis = item;
              break;
            case "BREAST_CANCER":
              this.breastCancer = item;
              break;
            case "DYSLIPIDEMIA":
              this.bloodFat = item;
              break;
            case "STROKE":
              this.stroke = item;
              break;
            case "GASTRIC_CANCER":
              this.gastricCarcinoma = item;
              break;
            case "COLORECTAL_CANCER":
              this.colorectalCancer = item;
              break;
            case "METABOLIC_SYNDROME":
              this.metabolicComprehensive = item;
              break;
            case "PERSONALIZED_SPORTS_1": // 个性化运动处方1
              this.personalizedMovement1 = item;
              break;
            case "EXERCISE_ASSESSMENT": // 运动行为评估
              this.motorBehavior = item;
              break;
            case "DIETARY_HABITS": // 膳食习惯
              this.dietaryHabits = item;
              break;
            case "LIFESTYLE": // 生活方式
              this.life = item;
              break;
            case "HEALTH_GUIDE": // 健康改善行动
              this.healthAction = item;
              break;
          }
        }
      });
    },
    getPdfM() {
      htmlToPdf(document.querySelector("#containerRef"), "个人健康评估报告");
    },
    onBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@media print {
  @page {
    size: auto;
    margin: 5mm 10mm;
  }
}
@import "@/styles/report.scss";

/*滚动条样式*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: #ccc;
}
.container-title {
  position: fixed;
  left: 0;
  top: 0;
}
.container-report {
  padding-top: 50px;
}
</style>
