<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">21</div>
        <div class="fz20">健康改善行动指南</div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <p style="line-height: 30px; margin-bottom: 10px">
          根据您目前的健康情况，我们为您提供了“健康改善行动指南”，为您设定了控制目标以及改善途径。希望您能认真阅读，并立即开始实施健康改善行动。有任何问题请随时咨询医生。
        </p>
        <!-- 1 -->
        <div v-if="healthData.smokeVO.smokeYear">
          <table class="tableStyle tCenter">
            <tr class="tableTitle">
              <td class="fz16" colspan="5">危险因素控制目标及改善途径</td>
            </tr>
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">吸烟</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">戒烟</td>
            </tr>
            <tr>
              <td colspan="5">
                您的吸烟年限为<span class="smallTitle2">{{
                  healthData.smokeVO.smokeYear || "-"
                }}</span
                >年，吸烟指数为<span class="smallTitle2">{{
                  healthData.smokeVO.smokeIndex || "-"
                }}</span>
                ，吸烟指数超过400即为肺癌的高危因素。烟雾中含有上百种有害的化学物质，其中许多已被证明是致癌的。无论身体是否已经出现不适，戒烟都是越早越好。据相关研究表明：戒烟一年以后，患冠心病的几率比吸烟者低50%;10年后患肺癌风险与普通人无异。
                温馨提示：保持6个月以上不吸烟才算成功戒烟。
              </td>
            </tr>
          </table>
          <p class="lh30">实现戒烟的方法</p>
          <p class="lh30">
            （1）一个戒烟或减少吸烟支数的计划，并从现在开始实施。
          </p>
          <p class="lh30">
            （2）扔掉吸烟用具，如打火机、烟灰缸、香烟，减少你的“条件反射”。
          </p>
          <p class="lh30">
            （3）坚决拒绝香烟的引诱，经常提醒自己，再吸一支烟足以令戒烟的计划前功尽弃。
          </p>
          <p class="lh30">（4）避免去以前习惯吸烟的场所或活动。</p>
          <p class="lh30">
            （5）餐后喝水、吃水果或散步，摆脱饭后一支烟的想法。
          </p>
          <p class="lh30">
            （6）烟瘾来时，立即做深呼吸活动，喝杯水或咀嚼无糖分的口香糖。
          </p>
          <p class="lh30">
            （7）告诉别人你已经戒烟，不要给香烟，也不要在您面前吸烟。
          </p>
          <p class="lh30">
            （8）写下你认为的戒烟理由，如为了自己的健康、为家人着想、为省钱等等，随身携带，当你烟瘾犯了时可以拿出来告诫自己。
          </p>
          <p class="lh30">
            （9）当你真的觉得戒烟很困难时，可以找专业医生（戒烟门诊）寻求帮助，取得家人和朋友的支持对干成功戒烟也至关重要。
          </p>
        </div>
        <!-- 2 -->
        <div
          v-if="
            healthData.bmiVO.obeRiskType === '超重' ||
            healthData.bmiVO.obeRiskType === '肥胖'
          "
        >
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">
                {{ healthData.bmiVO.obeRiskType }}
              </td>
              <td class="fz16">努力目标</td>
              <td class="fz16">BMI保持在18.5-24</td>
            </tr>
            <tr>
              <td colspan="5">
                您的体重为<span class="smallTitle2">{{
                  healthData.bmiVO.weight + "kg"
                }}</span
                >，体重指数（BMI）为<span class="smallTitle2">{{
                  healthData.bmiVO.bmi
                }}</span
                >，说明您的体重已超过正常范围（18.5≤BMI＜24）。（*
                运动员、孕妇、被看护的病人不述BMI解释不适用）。
              </td>
            </tr>
          </table>
          <p style="line-height: 30px">降低体重的方法</p>
          <p>(1) 少吃：</p>
          <p class="lh30">① 少吃是控制体重的重要途径之一。</p>
          <p class="lh30">
            ②
            注意挑选热量较低的食物(如同样重量的五花肉，其热量是纯瘦肉的3倍，是鱼肉的6倍)
          </p>
          <p class="lh30">③ 少吃热量较高的零食，如花生、瓜子等。</p>
          <p class="lh30">④ 减慢吃饭速度，吃得越快，往往吃得越多。</p>
          <p>(2) 多动：</p>
          <p class="lh30">① 各种类型的身体活动对体重控制都有帮助。</p>
          <p class="lh30">
            ②
            较长时间的有氧运动，机体可更多地燃烧脂肪来提供能量，所以您最好每次至少锻炼30分钟。
          </p>
          <p class="lh30">
            ③
            坚持每天步行或骑自行车上、下班；若需乘公共汽车，可以提前几个站下车；若上楼尽量选择走楼梯,少乘电梯
          </p>
          <p class="lh30">
            ④
            最好能加入公园或小区里的锻炼队伍，大家一起锻炼。如果您正在为超重发愁，迫切希望降低体重，您可以咨询医生如何进行健康体重管理！
          </p>
        </div>
        <!-- 3 -->
        <div v-if="healthData.bloodPressureVO.bloodPressureType === '高压偏高'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">高压偏高</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">控制在90/60mmHg和130/85mmHg之间</td>
            </tr>
            <tr>
              <td colspan="5">
                您的血压为<span class="smallTitle2"
                  >{{ healthData.bloodPressureVO.sbp }}/{{
                    healthData.bloodPressureVO.dbp
                  }}
                  mmHg</span
                >，已高于《中国高血压防治指南》所建议的正常血压范围（＜130/85mmHg）。血压越高，患心血管疾病的危险性就越大。
              </td>
            </tr>
          </table>
          <p class="lh30">改善血压的方法</p>
          <p class="lh30">
            (1)
            超重和肥胖、饮酒过量、膳食高盐是导致高血压的主要危险因素，请注意避免这些因素。
          </p>
          <p class="lh30">
            (2) 保持积极、乐观、平和的心态，学会正确处理工作生活中的压力。
          </p>
        </div>
        <!-- 4 -->
        <div v-if="healthData.bloodPressureVO.bloodPressureType === '高压过低'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">高压过低</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">控制在90/60mmHg和130/85mmHg之间</td>
            </tr>
            <tr>
              <td colspan="5">
                您的血压为<span class="smallTitle2"
                  >{{ healthData.bloodPressureVO.sbp }}/{{
                    healthData.bloodPressureVO.dbp
                  }}
                  mmHg</span
                >，已超出《中国高血压防治指南》所建议的正常血压范围（＜130/85mmHg）。长期的低血压会出现精神状态变差、疲乏无力、注意力不集中、嗜睡等。血压突然间降低的时候，也有可能会出现瞬间性的跌倒。
              </td>
            </tr>
          </table>
          <p class="lh30">改善血压的方法</p>
          <p class="lh30">(1) 血压过低要饮食调养，补充蛋白质。</p>
          <p class="lh30">(2) 适当加强锻炼。</p>
          <p class="lh30">
            (3) 保持积极、乐观、平和的心态，学会正确处理工作生活中的压力
          </p>
        </div>
        <!-- 5 -->
        <div v-if="healthData.waistVO.waistType === '腰围偏大'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">腰围偏大</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">男：85cm；女：80cm</td>
            </tr>
            <tr>
              <td colspan="5">
                您的腰围为<span class="smallTitle2">{{
                  healthData.waistVO.waist + "cm"
                }}</span
                >，已超过正常范围（男：85cm；女：80cm）。目前公认腰围是衡量脂肪在腹部蓄积（向心性肥胖）程度最简单实用的指标。
              </td>
            </tr>
          </table>
          <p class="lh30">降低腰围的方法</p>
          <p class="lh30">
            （1）控制饮食总热量，多选择能量密度较低的食物，如蔬菜、水果、豆制品、杂粮等。
          </p>
          <p class="lh30">
            （2）改变生活习惯：吃完饭后不要立即坐下或趴睡，最好能保持站立的形式，可以选择散散步或做家务。
          </p>
          <p class="lh30">
            （3）走姿和坐姿要正确；走路时要抬头挺胸、摆动手臂；坐下时脊背打直，不要弯腰或挺腹，如此腰部才不易松垮。
          </p>
          <p class="lh30">
            （4）在全身运动的基础上，加强腰部锻炼；30分钟以上的全身运动有助干减少脂肪，从而减小腰围，而腰部运动如呼拉圈、仰卧起坐等，可增加腹部肌肉的弹性，改善体态。
          </p>
        </div>
        <!-- 6 -->
        <div v-if="healthData.tgVO.tgType === '甘油三酯偏高'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">甘油三酯偏高</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">降低至1.7 mmol/L 以下</td>
            </tr>
            <tr>
              <td colspan="5">
                您的甘油三酯为<span class="smallTitle2">{{
                  healthData.tgVO.tg + "mmol/L"
                }}</span
                >，已高于《中国成人血脂异常防治指南》所建议的正常血压范围（＜1.7mmol/L）。甘油三酯是引起动脉阻塞的重要危险因素。
              </td>
            </tr>
          </table>
          <p class="lh30">降低腰围的方法</p>
          <p class="lh30">
            （1）控制饮食总热量，减少脂肪尤其是动物性脂肪的摄入。
          </p>
          <p class="lh30">
            （2）多吃蔬菜水果，尤其是富含膳食纤维的食物，如芹菜、韭菜、胡萝卜、柚子等。
          </p>
          <p class="lh30">
            （3）多吃粗粮和薯类食物，如燕麦、荞麦、地瓜、山药等。
          </p>
          <p class="lh30">（4）加强运动锻炼。</p>
          <p class="lh30">（5）定期测量血脂。</p>
        </div>
        <!-- 7 -->
        <div v-if="healthData.hdlcVO.hdlcType === 'HDL-C偏低'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">HDL-C偏低</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">升高至1.04 mmol/L 以上</td>
            </tr>
            <tr>
              <td colspan="5">
                您的高密度脂蛋白胆固醇（HDL-C）为<span class="smallTitle2">{{
                  healthData.hdlcVO.hdlc + "mmol/L"
                }}</span
                >，低于《中国成人血脂异常防治指南》所建议的理想范围，HDL-C低会引起冠心病、心血管事件增加。
              </td>
            </tr>
          </table>
          <p class="lh30">升高HDL-C的方法</p>
          <p class="lh30">（1）减轻体重：减轻体重可明显升高HDL-C。</p>
          <p class="lh30">（2）适量运动：每周运动1小时以上者可升高HDL-C。</p>
          <p class="lh30">（3）戒烟：吸烟者比不吸烟者的血浆HDL-C浓度低。</p>
          <p class="lh30">
            （4）适量饮酒：适度饮酒可升高HDL-C，但长期大量饮酒可损害肝脏功能。
          </p>
        </div>
        <!-- 8 -->
        <div v-if="healthData.ldlcVO.ldlcType === 'LDL-C偏高'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">LDL-C偏高</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">降低至3.52 mmol/L 以下</td>
            </tr>
            <tr>
              <td colspan="5">
                您的低密度脂蛋白胆固醇（LDL-C）为<span class="smallTitle2">{{
                  healthData.ldlcVO.ldlc + "mmol/L"
                }}</span
                >，已超过《中国成人血脂异常防治指南》所建议的理想范围，低密度脂蛋白增高可见于多种疾病，如肝炎、动脉硬化、心脑血管疾病等，正常人升高通常因为饮食不均衡，摄入的脂肪过高，或者吸烟饮酒引起，以及超重和肥胖也可能引起低密度脂蛋白升高，目前已证实其增高与冠心病发病风险有密切相关性。
              </td>
            </tr>
          </table>
          <p class="lh30">降低LDL-C的方法</p>
          <p class="lh30">（1）低油、低脂、低盐、低糖饮食。</p>
          <p class="lh30">（2）适量运动增强代谢来降低血脂。</p>
        </div>
        <!-- 9 -->
        <div v-if="healthData.sportLevelType === '不足'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">运动行为不足</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">有氧运动：30分钟/次×5次/周</td>
            </tr>
            <tr>
              <td colspan="5">
                您的运动行为为不足。适当的运动可以降低患慢性疾病的危险。研究表明规律的有氧运动对于健康有着多方面的益处。
              </td>
            </tr>
          </table>
          <p class="lh30">增加体力活动的方法</p>
          <p class="lh30">（1）利用计步器，用它来激励您每天增加运动总步数。</p>
          <p class="lh30">
            （2）坚持每天步行或骑自行车上、下班；若需乘公共汽车，可以提前几个站下车；若上楼尽量选择走楼梯，少乘电梯。
          </p>
          <p class="lh30">
            （3）每个周末做不同的运动，或和不同的伙伴一起做，一些新的活动内容会不知不觉地加入到您的计划中，同时也可体验到不同体育活动的乐趣。
          </p>
          <p class="lh30">
            （4）避免那些会于扰您计划实施的情况，例如：如果您因晚上少睡一小时而影响早起锻炼，那么尽量别很晚才休息；如果您发现把运动时间安排在一天的最后，经常会使您没有空余时间，试着安排在早一点的时间；如果有些朋友经常在晚上来找您聊天使计划无法实现，试着重新安排一下时间表使二者兼顾。
          </p>
          <p class="lh30">
            （5）向周围有经验的人请教他们是如何维持充足体力活动的，并付诸实施，看看效果如何。
          </p>
          <p class="lh30">
            （6）取得您的家人、朋友和同事的支持，如找一个朋友讨论一下您的计划并看看能否共同去锻炼。
          </p>
          <p class="lh30">*您可以向医生咨询，帮助您改善健康。</p>
        </div>
        <!-- 10 -->
        <div v-if="healthData.vegetableAndFruitIntake === '不足'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">蔬菜水果摄入不足</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">增加摄入，500克/天以上</td>
            </tr>
            <tr>
              <td colspan="5">
                您的蔬菜水果摄入不足。新鲜蔬菜水果因其富含维生素、矿物质、膳食纤维和植物化学物质，且能量低，对于预防血脂异常、糖尿病等具有重要作用。
              </td>
            </tr>
          </table>
          <p class="lh30">增加蔬菜水果摄入的方法</p>
          <p class="lh30">（1）刚开始多挑一些您真正喜欢的蔬菜和水果吃。</p>
          <p class="lh30">（2）犒劳自己一些非时令的水果。</p>
          <p class="lh30">（3）偶尔来点冷冻蔬菜、罐装天然果汁、干果等食品。</p>
          <p class="lh30">（4）尝试不常吃的蔬菜和水果。</p>
          <p class="lh30">（5）尝试喝新鲜的果汁和蔬菜汤。</p>
          <p class="lh30">
            （6）尝试生吃蔬菜，可以切成条状直接蘸酱或者用沙拉酱将各种蔬菜拌在一起。
          </p>
          <p class="lh30">
            （7）把不爱吃的蔬菜装饰一下，如在炒好的混合蔬菜上撒上芝麻粒、花生米、核桃等。
          </p>
        </div>
        <!-- 11 -->
        <!-- <table class="tableStyle tCenter"
          style="margin-top:10px;">
          <tr class="tableTitleHeader">
            <td class="fz16">目前状况</td>
            <td class="fz16">烹调习惯差</td>
            <td class="fz16">努力目标</td>
            <td class="fz16">烹调习惯良好</td>
          </tr>
          <tr>
            <td colspan="5">您炒菜做饭时经常把油烧得很热，您需要了解的是，当食用油加热到100℃以上时，即开始产生一系列对人体呼吸系统有害的物质。</td>
          </tr>
        </table>
        <p class="lh30">改善烹调习惯的方法</p>
        <p class="lh30">（1）为了减少吃进有害物质，炒菜时油锅的温度不要太高，不让油冒烟，油在锅里冒烟时的温度一般已超过200℃。</p>
        <p class="lh30">（2）煎炒的时间越短越好，不要把食品的表面煎得太焦黄，这样可减少致癌物的形成。</p> -->
        <!-- 12 -->
        <div v-if="healthData.beanProducts === '不足'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">大豆及豆制品摄入不足</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">增加大豆及豆制品摄入在30~50克/之间</td>
            </tr>
            <tr>
              <td colspan="5">您的大豆及豆制品摄入不足，请适量摄入。</td>
            </tr>
          </table>
          <p class="lh30">注意事项</p>
          <p class="lh30">
            您的大豆及豆制品摄入不足。大豆包括黄豆、黑豆和青豆，豆制品通常分为非发酵豆制品（如豆浆、豆腐、豆腐干、腐竹等）和发酵豆制品（如豆豉、腐乳、臭豆腐、豆汁等）。大豆及豆制品含有丰富的植物性雌激素，其化学结构与人体内的雌激素化学结构相似，对雄激素有一定的抑制作用，因此多吃该类食物可以预防由雄激素激发的前列腺癌发生。需要您注意的是，如果有肾脏疾病、胃炎、高尿酸血症等需要限制豆类摄入的，应该控制豆类的摄入，或按照医生的嘱咐进行食用。
          </p>
        </div>
        <!-- 13 -->
        <div v-if="healthData.drink === '是'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">经常（大量）饮酒</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">适量饮酒（≤15克/天）或不饮酒</td>
            </tr>
            <tr>
              <td colspan="5">
                您的饮酒过量。无节制的饮酒会使食物摄入量减少，以致发生多种营养素缺乏、急慢性酒精中毒、酒精性脂肪肝，严重时还会造成酒精性肝硬化；过量饮酒还会增加患高血压、中风、乳腺癌、消化道癌的危险。
              </td>
            </tr>
          </table>
          <p class="lh30">减少饮酒的方法</p>
          <p class="lh30">
            （1）列出减少饮酒量的好处，如不会呕吐、不会胃难受、不会因醉驾被拘留等。
          </p>
          <p class="lh30">
            （2）告诉亲戚朋友您开始戒酒的日子，请他们在您戒酒最初的日子里支持并鼓励您。
          </p>
          <p class="lh30">（3）找一个好朋友一起戒酒。</p>
          <p class="lh30">
            （4）向那些已经成功减少了饮酒量的人（他们的生活规律与您类似）请教，他们是如何戒酒或控制饮酒量的。
          </p>
          <p class="lh30">（5）避免去以前常饮酒的地方或场合。</p>
        </div>
        <!-- 14 -->
        <div v-if="healthData.highFat === '是'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">存在高脂饮食</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">避免高脂饮食</td>
            </tr>
            <tr>
              <td colspan="5">
                您目前存在高脂饮食。高脂肪食物是最不健康的食物之一，含脂肪过高的食物，比如油炸食品，会增加发生泛酸和肠易激综合症的机会。因为高脂肪食物很难消化，人体就会自动分泌出更多的胃酸。同样，进食过量也会刺激胃酸分泌过多。高脂食物给我们的身体带来不少负担，不仅容易长脂肪，还有害身体健康，长期坚持这种饮食习惯，很容易致癌。
              </td>
            </tr>
          </table>
          <p class="lh30">避免高脂饮食的方法</p>
          <p class="lh30">
            （1）少吃或不吃高脂肪的食物：如油炸食品、肥肉及动物内脏、奶油蛋糕等。
          </p>
          <p class="lh30">
            （2）多吃低脂肪的食物：所有的蔬菜和水果、大多数的鱼类、豆类等均属低脂肪食物。
          </p>
          <p class="lh30">
            （3）减少烹调用油，建议多采用煮、蒸、凉拌等烹调方法。
          </p>
          <!-- 15 -->
          <div v-if="healthData.diet === '否'">
            <table class="tableStyle tCenter" style="margin-top: 10px">
              <tr class="tableTitleHeader">
                <td class="fz16">目前状况</td>
                <td class="fz16 smallTitle2">不规律饮食</td>
                <td class="fz16">努力目标</td>
                <td class="fz16">规律饮食</td>
              </tr>
              <tr>
                <td colspan="5">
                  您目前存在不规律饮食。饮食不规律，不吃就不吃，一吃起来就吃太饱，会打乱胃肠消化的生物钟。当不吃早餐，或饥饿时，胃酸等消化液分泌后得不到食物中和，从而胃酸可侵蚀胃黏膜，加上幽门螺杆菌的感染，可引起急慢性胃炎、胃和十二指肠溃疡等疾病。另外暴饮暴食，可引起急性胃扩张，严重损害胃肠功能。还会导致皮肤干燥、贫血、细胞衰老等营养缺乏症状。
                </td>
              </tr>
            </table>
            <p class="lh30">改善不规律饮食的方法</p>
            <p class="lh30">
              （1）注意饮食规律。科学饮食规律即定时、定量进食，不吃或少吃零食。
            </p>
            <p class="lh30">
              （2）科学地分配三餐热量，早餐吃好，午餐吃饱，晚餐吃少。
            </p>
            <p class="lh30">
              （3）注意饮食均衡，合理膳食；不合理、不科学的饮食或营养结构会导致肥胖的发生。
            </p>
            <p class="lh30">
              （4）饮食与运动相结合，进食量与体力活动要平衡，保持适宜体重。
            </p>
          </div>
        </div>
        <!-- 16 -->
        <!-- <div>
          <table class="tableStyle tCenter"
            style="margin-top:10px;">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16">高腌制食品摄入</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">避免高腌制食品摄入</td>
            </tr>
            <tr>
              <td colspan="5">您目前存在高腌制食品摄入。腌制食品含盐量过高，会影响身体的水盐平衡，导致肾脏负担加重，容易引发高血压；如果大量进食腌菜，会使人体维生素C缺乏，从而导致抵抗力下降，引起各种炎症和溃疡；一些腌制的酸菜中含有太多的草酸和钙，食用后会被大量吸收，草酸钙会结晶沉积在泌尿系统中，最终形成泌尿系统的结石；易产生亚硝酸盐，长期食用有致癌隐患。</td>
            </tr>
          </table>
          <p class="lh30">改善高腌制食品摄入的方法</p>
          <p class="lh30">（1）尽量少做少吃少买，减少摄入量。</p>
          <p class="lh30">（2）多吃点维生素C含量丰富的瓜果蔬菜,维生素C是强还原剂，可以缓解亚硝酸盐中毒的症状。</p>
          <p class="lh30">（3）喝茶。茶中含有的茶多酚可以在胃酸环境下抑制亚硝酸胺的形成。</p>
        </div> -->
        <!-- 17 -->
        <div v-if="healthData.highSalt === '是'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">高盐饮食</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">避免高盐饮食</td>
            </tr>
            <tr>
              <td colspan="5">
                您目前存在高盐饮食。高盐饮食会让人体吸收过多的钠元素，容易使血压升高；盐分吃得过多会影响到淀粉的消化吸收速度，让人体的血糖上升，实验表明高盐饮食还和胰岛素抵抗联系密切；摄入过多的钠会增加肾脏的代谢负担，同时容易导致动脉粥样硬化；高盐食物能够刺激人的食欲，容易导致肥胖发生。
              </td>
            </tr>
          </table>
          <p class="lh30">改善高盐饮食的方法</p>
          <p class="lh30">（1）用限盐的勺子，每天尽量不超过5克。</p>
          <p class="lh30">（2）选择低盐的酱料和酱油。</p>
          <p class="lh30">（3）远离加工肉制品以及腌制食物。</p>
          <p class="lh30">（4）用低钠盐。</p>
          <p class="lh30">（5）出锅前再放盐。</p>
        </div>
        <!-- 18 -->
        <!-- <table class="tableStyle tCenter"
          style="margin-top:10px;">
          <tr class="tableTitleHeader">
            <td class="fz16">目前状况</td>
            <td class="fz16">精神压力大</td>
            <td class="fz16">努力目标</td>
            <td class="fz16">避免精神压力过大</td>
          </tr>
          <tr>
            <td colspan="5">您目前存在精神压力过大。 长期的精神压力过大，对健康造成一定的损害，发生抑郁症，焦虑症的风险也会增加。</td>
          </tr>
        </table>
        <p class="lh30">改善精神压力大的方法</p>
        <p class="lh30">（1）通过运动健身释放压力。</p>
        <p class="lh30">（2）保持良好的心态，尽量避免做一些力所不及的事情。</p>
        <p class="lh30">（3）以适当方式宣泄自己内心的不快和抑郁，开怀大笑是消除精神压力的最佳方法。</p>
        <p class="lh30">（4）可以做一些感兴趣的事：如看电影、旅游、聊天，听音乐，来释放压力。</p> -->
        <!-- 19 -->
        <div v-if="healthData.bloodSugarVO.bloodSugarType === '血糖偏高'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">血糖偏高</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">4.4-6.1mmol/L</td>
            </tr>
            <tr>
              <td colspan="5">
                您的血糖为<span class="smallTitle2">{{
                  healthData.bloodSugarVO.bloodSugar + "mmol/L"
                }}</span
                >，已超过《中国糖尿病协会》所建议的理想范围，高血糖的典型临床表现为“三多一少”，即多饮、多尿、多食和体重下降，以及血糖高、尿液中含有葡萄糖等，长期高血糖会使全身各个组织器官发生病变，导致各种急慢性并发症及心脑血管疾病。如胰腺功能衰竭、抵抗力下降、神经病变、糖尿病、肾病、糖尿病足坏死、心脑功能紊乱等。
              </td>
            </tr>
          </table>
          <p class="lh30">降低血糖的方法</p>
          <p class="lh30">
            （1）合理控制每天总热量摄入，平衡饮食结构，注意饮食多样化，以五谷类为主食。
          </p>
          <p class="lh30">
            （2）坚持适度运动，运动时间不能少于30分钟，每周至少坚持4~5天。
          </p>
          <p class="lh30">（3）避免吸烟喝酒，以免破坏血管。</p>
          <p class="lh30">（4）多注意休息，避免熬夜。</p>
          <p class="lh30">（5）定期监测血压、血糖和血脂。</p>
        </div>
        <!-- 20 -->
        <div v-if="healthData.bloodSugarVO.bloodSugarType === '血糖过低'">
          <table class="tableStyle tCenter" style="margin-top: 10px">
            <tr class="tableTitleHeader">
              <td class="fz16">目前状况</td>
              <td class="fz16 smallTitle2">血糖过低</td>
              <td class="fz16">努力目标</td>
              <td class="fz16">
                空腹在4.4-6.1mmol/L，餐后2小时在5.0-7.2mmol/L
              </td>
            </tr>
            <tr>
              <td colspan="5">
                您的血糖为<span class="smallTitle2">{{
                  healthData.bloodSugarVO.bloodSugar + "mmol/L"
                }}</span
                >，已超过《中国糖尿病协会》所建议的理想范围，短期、轻度的低血糖只会使患者产生饥饿、心慌、出冷汗、乏力、颤抖等躯体不适感。严重（或长期）低血糖会导致反跳性高血糖及血糖波动，损害中枢神经系统，会出现肢体抽搐、癫痫样发作及精神失常，重者可导致昏迷甚至死亡。尤其是老人发生低血糖时，极易诱发心律紊乱、心绞痛、心肌梗死、脑中风等并发症。由于低血糖导致患者头晕、意识恍惚甚至昏厥，由此导致摔伤、骨折等意外事件的发生率大大增加。
              </td>
            </tr>
          </table>
          <p class="lh30">改善血糖的方法</p>
          <p class="lh30">
            （1）多吃膳食纤维。尽量选用天然膳食纤维的蔬菜，包括芹菜、竹笋、木耳和蘑菇类的食物等。
          </p>
          <p class="lh30">（2）少吃多餐。食物种类多样化。</p>
          <p class="lh30">
            （3）保证营养的均衡，补充蛋白质，包括肉类，奶类等。
          </p>
          <p class="lh30">（4）运动前检查血糖值。</p>
          <p class="lh30">（5）定期监测血压、血糖和血脂。</p>
          <p class="lh30" style="margin-top: 20px">您做好准备开始改变了吗？</p>
          <p class="lh30">
            恭喜您！当您阅读到这一部分时，说明您对异常危险因素的认识可能已促使您开始考虑如何采取行动来改善健康。我们真心希望您能立即采取行动，通过一个小改变来改善您的健康。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import { parseTime } from "@/utils/index";
export default {
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      parseTime,
      loading: true,
      healthData: {},
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        if (newValue) {
          this.listData = newValue;
          this.getData();
        }
      },
    },
    immediate: true,
    deep: true,
  },
  methods: {
    getData() {
      this.healthData = this.listData?.extend?.healthGuideVO;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
