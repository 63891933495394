<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">20</div>
        <div class="fz20">个性化膳食处方2</div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div>
          <p style="line-height: 30px">
            杯:容量为240毫升，相当于刚好能盛一袋/一盒牛奶(约240毫升)的杯子；
          </p>
          <p style="line-height: 30px">1匙:10毫升(餐馆常用的瓷制的小匙)；</p>
          <p style="line-height: 30px">
            碗:容量为240毫升，相当于刚好能盛一袋/一盒牛奶(约240毫升)的碗；
          </p>
          <p style="line-height: 30px">小盐勺:1平勺约2克盐；</p>
          <p style="line-height: 30px">
            以家用普通电饭煲配的小量杯来估计，1杯大米为(150克)3两半杯(75克)大米煮熟后约为1碗米饭。
          </p>
        </div>
        <div style="page-break-after: always"></div>
        <!-- 一、疾病风险评估结果 -->
        <table class="tableStyle tCenter" style="margin-top: 10px">
          <tr class="tableTitleHeader">
            <td class="fz16" colspan="4">食物交换份</td>
          </tr>
          <tr class="tableTitleHeader">
            <td class="fz16">食物类别</td>
            <td class="fz16">份量描述</td>
            <td class="fz16">食物类别</td>
            <td class="fz16">份量描述</td>
          </tr>
          <!--  -->
          <tr>
            <td class="fontColor" rowspan="4">一份谷类</td>
            <td class="fontColor">一片切片面包（普通CD大小）</td>
            <td class="fontColor" rowspan="4">一份蔬菜</td>
            <td class="fontColor">3碗煮熟的青菜（150-180克/碗）</td>
          </tr>
          <tr>
            <td class="fontColor">一碗粥</td>
            <td class="fontColor">6碗生蔬菜</td>
          </tr>
          <tr>
            <td class="fontColor">1/3碗米饭、面条、煮熟的麦片等</td>
            <td class="fontColor">*建议以非淀粉类蔬菜为主</td>
          </tr>
          <tr>
            <td class="fontColor">二寸见方大小的馒头/花卷</td>
            <td class="fontColor"></td>
          </tr>
          <!--  -->
          <tr>
            <td class="fontColor" rowspan="8">一份水果</td>
            <td class="fontColor">一个中等大小水果（约网球大小）</td>
            <td class="fontColor" rowspan="8">一份脱脂乳制品</td>
            <td class="fontColor">一杯脱脂牛奶（250克）</td>
          </tr>
          <tr>
            <td class="fontColor">一根中等大小的香蕉</td>
            <td class="fontColor">3/5杯脱脂酸奶（150克）</td>
          </tr>
          <tr>
            <td class="fontColor">30粒葡萄或7粒冬枣</td>
            <td class="fontColor">30粒葡萄或7粒冬枣</td>
          </tr>
          <tr>
            <td class="fontColor">12粒草莓或12粒圣女果</td>
            <td class="fontColor">一寸见方的干乳酪</td>
          </tr>
          <tr>
            <td class="fontColor">半杯切成丁或块的水果</td>
            <td class="fontColor">一片夹三明治的乳酪片</td>
          </tr>
          <tr>
            <td class="fontColor">1/4杯（一杯约250毫升）水果干</td>
            <td class="fontColor"></td>
          </tr>
          <tr>
            <td class="fontColor">200毫升纯果汁</td>
            <td class="fontColor"></td>
          </tr>
          <tr>
            <td class="fontColor">*在条件允许的情况下，建议您选择新鲜的水果</td>
            <td class="fontColor"></td>
          </tr>
          <!--  -->
          <tr>
            <td class="fontColor" rowspan="6">一份肉禽鱼蛋</td>
            <td class="fontColor">50克瘦猪、牛、羊肉</td>
            <td class="fontColor" rowspan="6">
              一份坚果、种子、豆类（不加盐）
            </td>
            <td class="fontColor">15克去壳的杏仁（10粒）、腰果（10粒）</td>
          </tr>
          <tr>
            <td class="fontColor">50克去皮禽肉，如鸡肉</td>
            <td class="fontColor">15克去壳核桃仁（2个）、开心果（24粒）</td>
          </tr>
          <tr>
            <td class="fontColor">6只基围虾</td>
            <td class="fontColor">15克或1匙种子（如芝麻、葵花籽仁）</td>
          </tr>
          <tr>
            <td class="fontColor">100克鱼肉、贝类</td>
            <td class="fontColor">20粒花生仁、2个核桃仁</td>
          </tr>
          <tr>
            <td class="fontColor">*一副扑克牌大小的肉约为80-100克</td>
            <td class="fontColor">25克干豆类</td>
          </tr>
          <tr>
            <td class="fontColor">*一块鸡翅中大小的肉约为50克</td>
            <td class="fontColor">100克豆腐</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
export default {
  mixins: [drawMixin],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
