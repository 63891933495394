<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">14</div>
        <div class="fz20">
          膳食习惯总体评估报告
          <div>
            <span class="smallTitle1">您的膳食习惯评价等级为：</span>
            <span class="smallTitle2">{{ listData.riskNameType }}</span>
          </div>
        </div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div class="chart" style="margin-bottom: 30px">
          <img :src="chartToImgUrl" class="chartImg" />
          <div class="display-none">
            <Chart :cdata="cdata" @chartToImg="chartToImg" />
          </div>
          <div class="text">
            <div>
              膳食习惯与许多慢性病的发生、发展和预后密切相关。我们的生活就像过山车一样，物质水平提高得太快，而身体的代谢跟不上，一不留神就肥胖了；一测血压就高了；一抽血胆固醇、血脂就紊乱了，血糖也上来了。
            </div>
            <div>
              根据您提供的有关信息，您的膳食习惯评价等级为
              <span class="smallTitle2">{{ listData.riskNameType }}</span
              >。
            </div>
            <div>
              良好的健康饮食习惯可以使人获得更多对身体有益的营养物质，对于改善身体机能、增强免疫力以及促进人体本身的自愈能力都起到了积极的作用。荤素搭配，营养均衡，合理的膳食，远离“三高一凸”。
            </div>
          </div>
        </div>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td class="fz16" colspan="6">请继续保持以下膳食习惯</td>
          </tr>
          <tr class="tableTitle">
            <td colspan="6">
              从您的膳食习惯中，我们发现以下膳食习惯对您的身体是有益的，请您继续保持。
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in keep" :key="index">{{ item }}</td>
          </tr>
        </table>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td class="fz16" colspan="6">以下膳食习惯需要立即改善</td>
          </tr>
          <tr>
            <td colspan="6">
              不良的膳食习惯会对您的身体健康带来负面影响。您若现患糖尿病、高血压、高血脂或冠心病等慢性生活方式疾病，纠正下列膳食习惯将有助于改善您的健康指标；即使目前无疾病症状，改善以下膳食习惯也将大大降低您未来的患病风险。因此建议您在医护人员的指导下立即采取措施改变这些不良膳食习惯以改善您的健康状况。
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in needChange" :key="index">{{ item }}</td>
          </tr>
        </table>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td class="fz16" colspan="6">如何培养健康的膳食习惯</td>
          </tr>
          <tr>
            <td colspan="6">
              每天喝6~8杯水；少吃隔顿、隔夜饭菜，不吃过期和腐败变质的食物；进餐定时定量，细嚼慢咽；粗细搭配、荤素均衡；餐餐有蔬菜，天天有水果；适量摄入鱼、肉、蛋等高蛋白食物，有条件者，可多选海鱼和虾类；常喝牛奶，每天最好吃1次豆制品和少量坚果；饮食清淡，少油少盐；适当补钙、维生素D、铁、维生素A等；正确选择保健食品，但不能代替治疗。
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import Chart from "@/components/echart/diseaseChart/chart";
export default {
  components: { Chart },
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      keep: [], // 继续保持
      needChange: [], // 需要改变
      riskNameTypeNum: 0,
      chartToImgUrl: "",
      cdata: {
        grid: {
          top: 30, //距离容器上边界40像素
          right: 10,
          left: 50,
          bottom: 35,
        },
        xAxis: {
          type: "category",
          axisLine: false,
          axisLabel: {
            show: true,
            interval: 0,
            lineHeight: 14,
            color: "#000",
          },
          data: ["当前等级"],
        },
        yAxis: {
          axisLine: false,
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#f5f5f5"],
              width: 1,
              type: "solid",
            },
          },
          max: 3,
          min: 0,
          axisLabel: {
            color: "#000",
            formatter: function (value) {
              var texts = [];
              if (value === 1 || value === "1") {
                texts.push("差");
              } else if (value === 2 || value === "2") {
                texts.push("良");
              } else if (value === 3 || value === "3") {
                texts.push("优");
              } else if (value === 0 || value === "0") {
                texts.push("无");
              }
              return texts;
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 30,
            data: [],
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#02A4FF" },
                { offset: 1, color: "#0177FF" },
              ]),
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        if (newValue) {
          this.listData = newValue;
          this.getData();
        }
      },
    },
    immediate: true,
    deep: true,
  },
  methods: {
    getData() {
      this.keep = this.listData?.extend?.keep;
      this.needChange = this.listData?.extend?.needChange;
      this.formatter();
    },
    chartToImg(val) {
      this.chartToImgUrl = val;
    },
    formatter() {
      const riskNameType = this.listData.riskNameType; // 当前等级
      if (riskNameType === "差") {
        this.riskNameTypeNum = 1;
      } else if (riskNameType === "良") {
        this.riskNameTypeNum = 2;
      } else if (riskNameType === "优") {
        this.riskNameTypeNum = 3;
      }
      this.cdata.series[0].data = [this.riskNameTypeNum];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
.title-box {
  display: flex;
  flex-direction: column;
}
.smallTitle1 {
  display: inline-block;
  margin-top: 10px;
  color: #656565;
  font-size: 14px;
  font-weight: 400;
}
.smallTitle2 {
  color: #1890ff;
  font-size: 14px;
  font-weight: 400;
}
.chart {
  display: flex;
  // align-items: center;
  .text {
    width: 557px;
    height: 302px;
    background: #f6f6f6;
    border-radius: 10px;
    margin-left: 70px;
  }
}
</style>
