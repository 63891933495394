<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">16</div>
        <div class="fz20">
          运动行为评估报告
          <div>
            <span class="smallTitle1">您的运动行为评价等级为：</span>
            <span class="smallTitle2">{{ sportLevelType }}</span>
          </div>
        </div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div class="chart" style="margin-bottom: 30px">
          <img :src="chartToImgUrl" class="chartImg" />
          <div class="display-none">
            <Chart :cdata="cdata" @chartToImg="chartToImg" id="motorBehavior" />
          </div>
          <div class="text">
            <div>
              运动作为日常生活的一部分，与我们的健康息息相关，大量的研究证实，长期适量的运动可以防治疾病、增强体质、促进健康；反之，如果运动不足，则患慢性病的风险大大增加。因此，清楚了解自己目前的运动行为，是维持或改善健康应该迈出的第一步。
            </div>
            <div>
              根据您提供的有关信息，您的运动行为评价等级为：
              <span class="smallTitle2">{{ sportLevelType }}</span>
            </div>
          </div>
        </div>
        <div class="chart" style="margin-bottom: 30px">
          <img :src="chartToImgUrl1" class="chartImg" />
          <div class="display-none">
            <Chart
              :cdata="cdata2"
              @chartToImg="chartToImg1"
              id="motorBehavior2"
            />
          </div>
          <div class="text">
            <div>
              不同强度的运动对身体的刺激程度不同，所以不同的运动强度就有不同的适宜人群。
            </div>
            <div>
              步行：如在小区附近散步，引起的机体反应较小，适用于康复期病人、老年人、初始锻炼者。
            </div>
            <div>
              中等强度：如骑自行车，对身体刺激适中，长期这样的锻炼能提高身体机能水平，且发生运动损伤的风险小，糖尿病、高血压等慢性病患者推荐长期采用这样的强度。
            </div>
            <div>
              高强度：如赛跑，对身体刺激大，引起机体反应也较大，适用于有锻炼经历的，慢性病患者不建议采用。
            </div>
          </div>
        </div>
        <!-- 一、疾病风险评估结果 -->
        <div class="titleStyle">指导建议</div>
        <p style="line-height: 30px">
          1、运动行为是指任何由骨骼肌收缩引起的能量消耗增加的身体活动；体育锻炼是指有计划的、有组织的、可重复的，旨在促进或维持一种或多种体适能为目的的运动行为。可见，运动行为包括人们经常提及的“体育锻炼”，此外，还包括工作、家务、交通等其他日常活动。因此，增加运动可以从增加专门的体育锻炼、工作相关的身体活动和家务劳动以及改变出行方式来增加运动量。
        </p>
        <p style="line-height: 30px">
          2、根据评价结果，您目前的运动行为
          <span class="smallTitle2">{{ sportLevelType }}</span
          >。
          <span v-if="sportLevelType === '不足'"
            >为了预防“运动不足病”（指与运动不足直接相关的一组疾病，包括肥胖症、高血压等），提高身体素质，您需要适当增加身体活动量。建议您先从低强度的运动项目开始规律锻炼，再根据身体对运动强度的承受能力，慢慢增加运动强度和运动时间，最终达到指南推荐的运动量，即“健康成年人至少需要每天30分钟，每周5天中等强度的运动量”。</span
          >
          <span v-if="sportLevelType === '适度'"></span>
          <span v-if="sportLevelType === '充分'"
            >根据评价结果，您目前的运动行为充分。经常运动可以促进血液循环，增强体质，加快新陈代谢，调节神经系统的应答反应，减缓人体神经系统的衰老速度。不断的改善以及提高人体各个器官的机能，增强抵抗力，预防心血管等各种心脏性疾病的发生。同时运动能够增加人体肌肉，保持充沛的精力和活力，还能有助于皮肤的清洁和排毒，改善皮肤，让整体肤质变得更健康。希望您继续保持。</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import Chart from "@/components/echart/diseaseChart/chart";
export default {
  components: { Chart },
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      sportLevelType: "",
      sportStrengthType: "",
      riskNameTypeNum: 0,
      riskReferenceNameTypeNum: 0,
      chartToImgUrl: "",
      chartToImgUrl1: "",
      cdata: {
        grid: {
          top: 30, //距离容器上边界40像素
          right: 10,
          left: 60,
          bottom: 35,
        },
        xAxis: {
          type: "category",
          axisLine: false,
          axisLabel: {
            show: true,
            interval: 0,
            lineHeight: 14,
            color: "#000",
          },
          data: ["当前等级"],
        },
        yAxis: {
          axisLine: false,
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#f5f5f5"],
              width: 1,
              type: "solid",
            },
          },
          max: 3,
          min: 0,
          axisLabel: {
            color: "#000",
            formatter: function (value) {
              var texts = [];
              if (value === 1 || value === "1") {
                texts.push("不足");
              } else if (value === 2 || value === "2") {
                texts.push("适度");
              } else if (value === 3 || value === "3") {
                texts.push("充分");
              } else if (value === 0 || value === "0") {
                texts.push("无");
              }
              return texts;
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 30,
            data: [],
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#02A4FF" },
                { offset: 1, color: "#0177FF" },
              ]),
            },
          },
        ],
      },
      cdata2: {
        grid: {
          top: 30, //距离容器上边界40像素
          right: 10,
          left: 60,
          bottom: 35,
        },
        xAxis: {
          type: "category",
          axisLine: false,
          axisLabel: {
            show: true,
            interval: 0,
            lineHeight: 14,
            color: "#000",
          },
          data: ["运动强度"],
        },
        yAxis: {
          axisLine: false,
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#f5f5f5"],
              width: 1,
              type: "solid",
            },
          },
          max: 3,
          min: 0,
          axisLabel: {
            color: "#000",
            formatter: function (value) {
              var texts = [];
              if (value === 1 || value === "1") {
                texts.push("低强度");
              } else if (value === 2 || value === "2") {
                texts.push("中等强度");
              } else if (value === 3 || value === "3") {
                texts.push("高强度");
              } else if (value === 0 || value === "0") {
                texts.push("无");
              }
              return texts;
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 30,
            data: [],
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#02A4FF" },
                { offset: 1, color: "#0177FF" },
              ]),
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        if (newValue) {
          this.listData = newValue;
          this.getData();
        }
      },
    },
    immediate: true,
    deep: true,
  },
  methods: {
    getData() {
      this.sportLevelType =
        this.listData?.extend?.exerciseAssessmentVO?.sportLevelType;
      this.sportStrengthType =
        this.listData?.extend?.exerciseAssessmentVO?.sportStrengthType;

      this.formatter();
    },
    chartToImg(val) {
      this.chartToImgUrl = val;
    },
    chartToImg1(val) {
      this.chartToImgUrl1 = val;
    },
    formatter() {
      const riskNameType =
        this.listData?.extend?.exerciseAssessmentVO?.sportLevelType; // 当前风险
      const riskReferenceNameType =
        this.listData?.extend?.exerciseAssessmentVO?.sportStrengthType; // 当前风险

      if (riskNameType === "不足") {
        this.riskNameTypeNum = 1;
      } else if (riskNameType === "适度") {
        this.riskNameTypeNum = 2;
      } else if (riskNameType === "充分") {
        this.riskNameTypeNum = 3;
      }

      if (riskReferenceNameType === "低强度") {
        this.riskReferenceNameTypeNum = 1;
      } else if (riskReferenceNameType === "中等强度") {
        this.riskReferenceNameTypeNum = 2;
      } else if (riskReferenceNameType === "高强度") {
        this.riskReferenceNameTypeNum = 3;
      }
      this.cdata.series[0].data = [this.riskNameTypeNum];
      this.cdata2.series[0].data = [this.riskReferenceNameTypeNum];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
