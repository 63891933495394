<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">15</div>
        <div class="fz20">
          生活方式评估报告
          <div>
            <span class="smallTitle1">您本次生活方式评分属于：</span>
            <span class="smallTitle2">{{ listData.riskNameType }}</span>
          </div>
        </div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div style="margin-bottom: 10px">
          医学研究证实，许多个人行为和生活因素会预示并影响着健康趋势和寿命。对您目前的生活方式信息进行汇总分析后，产生了本报告，为您展示了目前的生活方式因素是如何潜在地影响您的健康。希望您通过阅读此报告，发现不健康习惯，开始采取行动，控制健康风险
        </div>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td class="fz16" colspan="2">主要分析建议</td>
          </tr>
          <tr>
            <td class="fz16">饮食</td>
            <td>
              <span class="smallTitle2">{{ listData.extend.food || "" }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              膳食结构与健康有着密切关系，膳食结构不合理会引起有些营养成分缺乏、有些营养成分过剩,都会引起疾病。如蔬菜摄入不足,常会引起维生素c缺乏,可引起坏血病。油脂摄入过多,则会引起高血脂,进而发展为胆结石、脂肪肝、动脉硬化等。
            </td>
          </tr>
          <tr>
            <td class="fz16">运动行为</td>
            <td>
              <span class="smallTitle2">{{ listData.extend.sport || "" }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              长期不运动是健康的大敌，世界卫生组织指出，身体活动不足已成为影响全球死亡率的第四大危险因子，每年有6%的死亡率与缺乏运动有关。18~64岁的人每周至少应有150分钟的中等强度有氧运动或75分钟的高强度有氧运动，每天跳操20分钟就能达到这项最低标准。
            </td>
          </tr>
          <tr>
            <td class="fz16">吸烟</td>
            <td>
              <span class="smallTitle2">{{ listData.extend.smoke || "" }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              现已确定，吸烟和被动吸烟可导致多种癌症，还会引发多种心血管疾病和多种呼吸系统疾病，同时可损害体内几乎所有器官。电子烟危害更大。
            </td>
          </tr>
          <tr>
            <td class="fz16">饮酒</td>
            <td>
              <span class="smallTitle2">{{ listData.extend.drink || "" }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              经常喝酒对于健康的危害很大，长期饮酒会导致人的反应变慢，记忆力下降，并且会对胃肠道造成损伤，可以引起慢性胃炎、胃溃疡，过多的摄入酒精还会损害肝功能，甚至可以引起酒精肝，肝硬化，脾大。只有用葡萄酿造的天然红葡萄酒才对心血管系统有一定的保健作用。
            </td>
          </tr>
          <tr>
            <td class="fz16">精神压力</td>
            <td>
              <span class="smallTitle2">{{
                listData.extend.spirit || ""
              }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              长期处于高度紧张的压力状态下，会过度情绪化，容易精神抑郁，变成悲观主义者。容易造成饮食失调，营养不均，会出现恶心干呕，头疼发烧，腹泻腹痛等不良生理反应。同时还会导致免疫力下降，疾病丛生。
            </td>
          </tr>
          <tr>
            <td class="fz16">睡眠</td>
            <td>
              <span class="smallTitle2">{{ listData.extend.sleep || "" }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              睡眠是生命活动中重要的组成部分，是身体进行自我检测和修复的过程。睡眠质量差会加速身体的衰老，精神状态变差，还会让抵抗力变得薄弱，容易出现免疫功能下降情况，使重要器官功能下降，特别是肝脏功能受损，肝脏一旦发生病变，毒素排泄受阻之外消化能力还会降低，进而引发各种不良后果。
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
export default {
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      riskFactorList: [],
      improveSchemeArrList: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        if (newValue) {
          this.listData = newValue;
          this.getData();
        }
      },
    },
    immediate: true,
    deep: true,
  },
  methods: {
    getData() {
      this.riskFactorList = this.listData?.riskFactorsVOList;
      this.improveSchemeArrList = this.listData?.improveSchemeArr;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
