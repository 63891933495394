import { render, staticRenderFns } from "./diabetes.vue?vue&type=template&id=5527555b&scoped=true&"
import script from "./diabetes.vue?vue&type=script&lang=js&"
export * from "./diabetes.vue?vue&type=script&lang=js&"
import style0 from "./diabetes.vue?vue&type=style&index=0&id=5527555b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5527555b",
  null
  
)

export default component.exports