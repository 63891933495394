<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">18</div>
        <div class="fz20">个性化运动处方2</div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div class="titleStyle">运动中有哪些注意事项?</div>
        <div>
          <p style="line-height: 30px">
            1、力量练习时举起重物的速度要慢，放下重物的速度也要慢；要注意观察血压的变化，避免上升过高，血压>180/110mmHg应终止运动；动作要有节律不要用度过力，不要长时间使头低于心脏的位置。
          </p>
          <p style="line-height: 30px">
            2、锻炼过程中要遵循循序渐进和持之以恒的原则，并且注意饮食控制，保持热能负平衡减少胆固醇和饱和脂肪酸的摄入，这样才能取得良好的减肥效果（另见膳食处方）。
          </p>
        </div>
        <div class="titleStyle">有氧运动中如何监测心率?</div>
        <div>
          <p style="line-height: 30px">
            通过数脉搏自测心率，即用一只手的食指和中指在另一只手桡动脉的地方触摸脉搏10秒钟，得到的搏动次数再乘以6就是心率值。如果这个心率值低于您的目标范围，应增加运动强度；如果这个心率值高于您的目标范围，应降低运动强度；每次改变运动强度后，过10分钟再监测一次。也可以通过心率表来监测心率。
          </p>
        </div>
        <div class="titleStyle">如何理解“运动中的疲劳感觉?</div>
        <div>
          <p style="line-height: 30px">
            "运动中的疲劳感觉"即为运动时的主关疲劳感觉，它是指锻炼者的主观费力程度。它不是对身体某一方面感觉的反映，而是对运动中个人的适应能力水平、身体疲劳情况等的整体自我感觉。等运动强度水平相对稳定（通常是持续运动10分钟）后，用"很轻松"、"轻松、"有些吃力、"吃力、"很吃力或"精疲力竭"这6个等级中的1个来表示对当前运动量的疲劳感觉，从而监测运动强度。
          </p>
        </div>
        <div class="titleStyle">力量练习中有哪些正确的技巧?</div>
        <div>
          <p style="line-height: 30px">
            1、您不用花钱购置任何专门的器械，就可以在家里开始力量练习。可以用装水或装沙子的矿泉水瓶进行上下肢锻炼，可以在床垫或沙发上做腹部练习。
          </p>
          <p style="line-height: 30px">
            2、为保证安全，应注意正确的呼吸，不要憋气。一个容易的呼吸技巧是，在最轻松部分的期间吸气，在最艰难部分的期间呼气。
          </p>
          <p style="line-height: 30px">
            3、保持正确的技术动作。根据每个动作的具体描述进行练习，假如您不能再保持正确的技术动作，应停止本次练习，而且您可能需要减轻重量。
          </p>
          <p style="line-height: 30px">
            4、定期调整重量。当您能比推荐的重复次数多完成1~2次时，可以增加2~10%的重量。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
export default {
  mixins: [drawMixin],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
