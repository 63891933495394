<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">13</div>
        <div class="fz20">
          代谢综合征风险评估报告
          <div>
            <span class="smallTitle1">您代谢综合征的发病风险为：</span>
            <span class="smallTitle2">{{ listData.riskNameType }}</span>
          </div>
        </div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div class="chart" style="margin-bottom: 30px">
          <img :src="chartToImgUrl" class="chartImg" />
          <div class="display-none">
            <Chart :cdata="cdata" @chartToImg="chartToImg" />
          </div>
        </div>
        <div class="titleStyle">一、与代谢综合征发病相关的危险因素</div>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td class="fz16">危险因素</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.riskFactor }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">本次检查 {{ parseTime(listData.theTime) }}</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.theValue }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">上次检查 {{ parseTime(listData.lastTime) }}</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.lastValue }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">变化情况</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.changeSituation }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">参考值</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.referValue }}
            </td>
          </tr>
        </table>
        <div class="titleStyle">二、改善以下因素降低您的代谢综合征发病风险</div>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td v-for="(item, index) in improveSchemeArrList" :key="index">
              {{ item }}
            </td>
          </tr>
        </table>
        <div class="titleStyle">三、预防建议</div>
        <div>
          <p style="line-height: 30px">
            代谢综合征是指在个体中多种代谢异常情况集结存在的现象，这些异常包括糖尿病或糖调节受损、高血压、血脂紊乱、全身或腹部肥胖等。这些代谢异常大多为动脉硬化性心、脑及周围血管（简称心血管疾病）的危险因素，故代谢综合征患者是心血管疾病的高危人群。代谢综合征人群可通过减轻体重、减少脂肪摄入、坚持运动等治疗性生活方式改善措施来有效控制体重，改善血脂紊乱，控制血压水平，从而降低代谢综合征引起的更大危害。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import { parseTime } from "@/utils/index";
import Chart from "@/components/echart/diseaseChart/chart";
export default {
  components: { Chart },
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      parseTime,
      loading: true,
      riskFactorList: [],
      improveSchemeArrList: [],
      riskNameTypeNum: 0,
      chartToImgUrl: "",
      cdata: {
        grid: {
          top: 30, //距离容器上边界40像素
          right: 10,
          left: 100,
          bottom: 35,
        },
        xAxis: {
          type: "category",
          axisLine: false,
          axisLabel: {
            show: true,
            interval: 0,
            lineHeight: 14,
            color: "#000",
          },
          data: ["当前风险"],
        },
        yAxis: {
          axisLine: false,
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#f5f5f5"],
              width: 1,
              type: "solid",
            },
          },
          max: 3,
          min: 0,
          axisLabel: {
            color: "#000",
            formatter: function (value) {
              var texts = [];
              if (value === 1 || value === "1") {
                texts.push("低危人群");
              } else if (value === 2 || value === "2") {
                texts.push("高危人群");
              } else if (value === 3 || value === "3") {
                texts.push("代谢综合征人群");
              } else if (value === 0 || value === "0") {
                texts.push("无");
              }
              return texts;
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 30,
            data: [],
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#02A4FF" },
                { offset: 1, color: "#0177FF" },
              ]),
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        if (newValue) {
          this.listData = newValue;
          this.getData();
        }
      },
    },
    immediate: true,
    deep: true,
  },
  methods: {
    getData() {
      this.riskFactorList = this.listData?.riskFactorsVOList;
      this.improveSchemeArrList = this.listData?.improveSchemeArr;
      this.formatter();
    },
    chartToImg(val) {
      this.chartToImgUrl = val;
    },
    formatter() {
      const riskNameType = this.listData.riskNameType; // 当前风险

      if (riskNameType === "低危人群") {
        this.riskNameTypeNum = 1;
      } else if (riskNameType === "高危人群") {
        this.riskNameTypeNum = 2;
      } else if (riskNameType === "代谢综合征人群") {
        this.riskNameTypeNum = 3;
      }
      this.cdata.series[0].data = [this.riskNameTypeNum];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
