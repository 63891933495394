<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">19</div>
        <div class="fz20">个性化膳食处方1</div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div>
          <p style="line-height: 30px">
            合理的饮食，能提高人的健康水平，预防多种疾病的发生发展，延长寿命。《中国居民膳食指南》推荐：食物多样，谷类为主；吃动平衡，健康体重；多吃蔬菜、奶类、大豆；适量吃鱼、禽、蛋、瘦肉；少盐少油，控糖限酒；杜绝浪费，兴新食尚。根据您的当前状况，这您推荐了相应能量水平的食谱（表1），表中“食物举例”一栏列出了推荐的食物搭配举例及相应份量的描述，“推荐份数”是便于您在使用“食物交换份”（表2）的时候进行同类食物的等值（能量及其它营养素含量近似）替换，搭配出您所喜欢的食谱。请您事先咨询医生并获得其许可，在医生的指导下认真按照本食谱建议安排膳食并定期随访，只要坚持就一定能改善您的健康状况。有任何问题请随时咨询医生。
          </p>
        </div>
        <div style="page-break-after: always"></div>
        <!-- 一、疾病风险评估结果 -->
        <table class="tableStyle tCenter" style="margin-top: 10px">
          <tr class="tableTitleHeader">
            <td class="fz16" colspan="5">推荐食谱（1200千卡）</td>
          </tr>
          <tr class="tableTitleHeader">
            <td class="fz16">餐次</td>
            <td class="fz16">食物类别</td>
            <td class="fz16">推荐分数</td>
            <td class="fz16">食物举例</td>
            <td class="fz16">食物分量描述</td>
          </tr>
          <!-- 早餐 -->
          <tr>
            <td class="fontColor" rowspan="3">早餐</td>
            <td class="fontColor">水果</td>
            <td class="fontColor">0.5</td>
            <td class="fontColor">100克苹果</td>
            <td class="fontColor">中等大小的半个</td>
          </tr>
          <tr>
            <td class="fontColor">奶类（低脂、脱脂）</td>
            <td class="fontColor">1</td>
            <td class="fontColor">240克牛奶</td>
            <td class="fontColor">1杯</td>
          </tr>
          <tr>
            <td class="fontColor">谷薯杂豆类</td>
            <td class="fontColor">1</td>
            <td class="fontColor">25克干麦片</td>
            <td class="fontColor">2-3匙</td>
          </tr>
          <!-- 午餐 -->
          <tr>
            <td class="fontColor" rowspan="6">午餐</td>
            <td class="fontColor" rowspan="2">蔬菜</td>
            <td class="fontColor" rowspan="2">0.5</td>
            <td class="fontColor">150克油菜</td>
            <td class="fontColor">炒熟后约半碗</td>
          </tr>
          <tr>
            <td class="fontColor">100克西兰花</td>
            <td class="fontColor">炒熟后约小半碗</td>
          </tr>
          <tr>
            <td class="fontColor" rowspan="2">谷薯杂豆类</td>
            <td class="fontColor" rowspan="2">2</td>
            <td class="fontColor">25克大米</td>
            <td class="fontColor">煮熟后约大半碗</td>
          </tr>
          <tr>
            <td class="fontColor">25克玉米碴</td>
            <td class="fontColor">煮熟后约大半碗</td>
          </tr>
          <tr>
            <td class="fontColor">肉蛋类</td>
            <td class="fontColor">1</td>
            <td class="fontColor">100克鱼肉</td>
            <td class="fontColor">1副扑克牌大小</td>
          </tr>
          <tr>
            <td class="fontColor">水果</td>
            <td class="fontColor">1</td>
            <td class="fontColor">300克草莓</td>
            <td class="fontColor">中等大小12粒</td>
          </tr>
          <!-- 晚餐 -->
          <tr>
            <td class="fontColor" rowspan="5">晚餐</td>
            <td class="fontColor" rowspan="2">蔬菜</td>
            <td class="fontColor" rowspan="2">0.5</td>
            <td class="fontColor">150克芹菜</td>
            <td class="fontColor">炒熟后约半碗</td>
          </tr>
          <tr>
            <td class="fontColor">100克丝瓜</td>
            <td class="fontColor">炒熟后约小半碗</td>
          </tr>
          <tr>
            <td class="fontColor">谷薯杂豆类</td>
            <td class="fontColor">2</td>
            <td class="fontColor">50克挂面</td>
            <td class="fontColor">煮熟后约大半碗</td>
          </tr>
          <tr>
            <td class="fontColor">大豆类</td>
            <td class="fontColor">1</td>
            <td class="fontColor">50克豆腐干</td>
            <td class="fontColor">半幅扑克牌大小</td>
          </tr>
          <tr>
            <td class="fontColor">水果</td>
            <td class="fontColor">1</td>
            <td class="fontColor">200克橙子</td>
            <td class="fontColor">1个网球大小</td>
          </tr>
          <!-- 加餐 -->
          <tr>
            <td class="fontColor" rowspan="2">加餐</td>
            <td class="fontColor">奶类（低脂、脱脂）</td>
            <td class="fontColor">1</td>
            <td class="fontColor">150克低脂酸奶</td>
            <td class="fontColor">大半杯</td>
          </tr>
          <tr>
            <td class="fontColor">坚果、种子</td>
            <td class="fontColor">1</td>
            <td class="fontColor">15克核桃仁</td>
            <td class="fontColor">1个半</td>
          </tr>
          <tr>
            <td class="fontColor" colspan="10">
              *食谱中未包含烹调油(植物油)和食盐的用量，植物油应不超过10克，食盐应不超过5克
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
export default {
  mixins: [drawMixin],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
