<template>
  <div>
    <Echart
      :options="options"
      id="bottomCenterChart"
      @chartToImg="chartToImg"
      height="300px"
      width="400px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
      chartToImgUrl: "",
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    chartToImg(val) {
      this.$emit("chartToImg", val);
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = newData;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
