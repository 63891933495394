<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">17</div>
        <div class="fz20">个性化运动处方1</div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <p style="line-height: 30px; margin-bottom: 10px">
          生命在于运动，运动需要科学。适当地运动可以消耗体内脂肪，加快新陈代谢，稳定血压，增强和改善心肺功能，预防骨质疏松，调节心脏功能，还能有效地缓解抑郁和压力。根据您目前的健康情况，我们为您制定了一周的运动处方，请您事先咨询医生并获得其许可，在医生的指导下认真按照本运动处方进行运动并定期随访，只要坚持一定能改善您的健康状况。有任何问题请随时咨询医生。
        </p>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td colspan="8" class="fz16">一周锻炼安排表</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">锻炼内容</td>
            <td class="fz16">周一</td>
            <td class="fz16">周二</td>
            <td class="fz16">周三</td>
            <td class="fz16">周四</td>
            <td class="fz16">周五</td>
            <td class="fz16">周六</td>
            <td class="fz16">周日</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">有氧运动</td>
            <td
              class="fontColor"
              v-for="(item, index) in aerobicData"
              :key="index"
            >
              <img
                style="width: 17px; height: 17px"
                src="@/assets/bingo.png"
                v-if="item === true"
                alt=""
              />
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">力量练习</td>
            <td
              class="fontColor"
              v-for="(item, index) in powerData"
              :key="index"
            >
              <img
                style="width: 17px; height: 17px"
                src="@/assets/bingo.png"
                v-if="item === true"
                alt=""
              />
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">柔韧性练习</td>
            <td
              class="fontColor"
              v-for="(item, index) in flexibleData"
              :key="index"
            >
              <img
                style="width: 17px; height: 17px"
                src="@/assets/bingo.png"
                v-if="item === true"
                alt=""
              />
            </td>
          </tr>
        </table>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td colspan="2" class="fz16">有氧运动</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动方式</td>
            <td class="foontColor">
              步行、慢跑、走跑交替、上下楼梯、游泳、自行车、椭圆机、跳绳、健身操等
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动强度</td>
            <td class="foontColor">
              运动中的心率控制在{{ sports.headRate }}次/分钟
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动频率</td>
            <td class="foontColor">每周3-5次</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">能耗目标</td>
            <td class="foontColor" v-if="sports.target === '未发现体重记录'">
              {{ sports.target }}
            </td>
            <td class="foontColor" v-else>{{ sports.target }}千卡/天</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">建议</td>
            <td class="foontColor">
              运动前后应各安排5-10分钟的热身和放松活动，活动的内容均由轻度有氧运动和柔韧性练习组成，不要运动过度
            </td>
          </tr>
        </table>
        <div style="page-break-after: always"></div>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td colspan="2" class="fz16">力量练习</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动方式</td>
            <td class="foontColor">
              6个动作：推墙练习；背肌静力抗阻练习；胸肌静力抗阻练习；伸髋练习；深蹲练习；提踵练习
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">重复次数</td>
            <td class="foontColor">每个动作重复做6次为1组，完成1组</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动强度</td>
            <td class="foontColor">运动中的疲劳感觉以吃力为限度</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动频率</td>
            <td class="foontColor">每周2次</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">建议</td>
            <td class="foontColor">
              力量练习前后也应各安排5-10分钟的热身和放松活动
            </td>
          </tr>
        </table>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td colspan="2" class="fz16">柔韧性练习</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动方式</td>
            <td class="foontColor">
              6个动作：肱三头肌拉伸；前臂拉伸；弯腰侧拉伸；腰背部拉伸；腘绳肌拉伸；股四头肌拉伸
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">重复次数</td>
            <td class="foontColor">每个动作重复拉伸2次，每次持续10秒</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动强度</td>
            <td class="foontColor">以不出现疼痛或不适为限度</td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">运动频率</td>
            <td class="foontColor">每周2次</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
export default {
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      aerobicData: [], // 有氧运动
      flexibleData: [], // 柔韧性运动
      powerData: [], // 力量运动
      sports: {}, // 运动强度
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        if (newValue) {
          this.listData = newValue;
          this.getData();
        }
      },
    },
    immediate: true,
    deep: true,
  },
  methods: {
    getData() {
      this.aerobicData = this.listData?.extend?.week.aerobic;
      this.flexibleData = this.listData?.extend?.week.flexible;
      this.powerData = this.listData?.extend?.week.power;
      this.sports = this.listData?.extend?.sports;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
