<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">5</div>
        <div class="fz20">
          肥胖症评估报告
          <div>
            <div>
              <span class="smallTitle1">您的全身性肥胖评估结果为：</span>
              <span class="smallTitle2">{{ listData.extend.wholeBody }}</span>
            </div>
            <div>
              <span class="smallTitle1">您的中心性肥胖评估结果为：</span>
              <span class="smallTitle2">{{ listData.extend.center }}</span>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div class="chart" style="margin-bottom: 30px">
          <div>
            <img :src="chartToImgUrl" class="chartImg" />
            <div class="display-none">
              <Chart :cdata="cdata" @chartToImg="chartToImg" id="fat" />
            </div>
          </div>
          <div>
            <img :src="chartToImgUrl1" class="chartImg" />
            <div class="display-none">
              <Chart :cdata="cdata1" @chartToImg="chartToImg1" id="fat2" />
            </div>
          </div>
        </div>
        <!-- 一、疾病风险评估结果 -->
        <div class="titleStyle">
          一、中国成人超重和肥胖的体重指数和腰围界限值与相关疾病危险的关系
        </div>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td class="fz16">分类</td>
            <td v-for="(item, index) in tableData" :key="index">
              {{ item.classify }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">体重过低</td>
            <td v-for="(item, index) in tableData" :key="index">
              {{ item.low }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">体重正常</td>
            <td v-for="(item, index) in tableData" :key="index">
              {{ item.normal }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">超重</td>
            <td v-for="(item, index) in tableData" :key="index">
              {{ item.heavey }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">肥胖</td>
            <td v-for="(item, index) in tableData" :key="index">
              {{ item.fat }}
            </td>
          </tr>
        </table>
        <!-- 一、疾病风险评估结果 -->
        <div class="titleStyle">二、预防建议</div>
        <div>
          <p style="line-height: 30px">
            1、改变进食行为。如增加咀嚼次数，减慢进食速度，饭前先喝一碗汤，帮助消化液的分泌，又增加饱腹感，减少饮食量。
          </p>
          <p style="line-height: 30px">
            2、限制总热能摄入量。以减少谷类主食摄入量为主。摄入量应低于消耗量，以使体重逐渐下降。轻度肥胖者，每月体重下降0.5~1㎏。中度肥胖者每月体重减轻1～2㎏。
          </p>
          <p style="line-height: 30px">
            3、合理饮食。采用高蛋白、低脂肪、低糖、高维生素、低盐饮食。限制高脂肪食物，少食高热量的食物，多食蔬菜、杂粮，食用适量水果。低盐饮食即每一天摄入的钠盐少于5克。
          </p>
          <p style="line-height: 30px">
            4、坚持运动。运动应遵循选择适宜（适宜的运动项目、适宜的运动量）、循序渐进、持之以恒的原则。最佳运动时间为每天的15：00—17：00。如在饭前锻炼，至少要休息30分钟才能吃饭；饭后则至少要休息1.5个小时以上才能锻炼。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import Chart from "@/components/echart/diseaseChart/chart";
export default {
  components: { Chart },
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      tableData: [
        {
          classify: "体重指数（千克/平方米）",
          low: "≤18.4",
          normal: "18.5-23.9",
          heavey: "24.0-27.9",
          fat: "≥28",
        },
        {
          classify: "腰围＜80",
          low: "-",
          normal: "-",
          heavey: "增加",
          fat: "高",
        },
        {
          classify: "腰围80-90",
          low: "-",
          normal: "增加",
          heavey: "高",
          fat: "极高",
        },
        {
          classify: "腰围≥90",
          low: "-",
          normal: "高",
          heavey: "极高",
          fat: "极高",
        },
      ],
      wholeBodyNum: 0,
      centerNum: 0,
      chartToImgUrl: "",
      chartToImgUrl1: "",
      cdata: {
        grid: {
          top: 30, //距离容器上边界40像素
          right: 10,
          left: 60,
          bottom: 35,
        },
        xAxis: {
          type: "category",
          axisLine: false,
          axisLabel: {
            show: true,
            interval: 0,
            lineHeight: 14,
            color: "#000",
          },
          data: ["当前评估"],
        },
        yAxis: {
          axisLine: false,
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#f5f5f5"],
              width: 1,
              type: "solid",
            },
          },
          max: 4,
          min: 0,
          axisLabel: {
            color: "#000",
            formatter: function (value) {
              var texts = [];
              if (value === 1 || value === "1") {
                texts.push("偏瘦");
              } else if (value === 2 || value === "2") {
                texts.push("正常");
              } else if (value === 3 || value === "3") {
                texts.push("过重");
              } else if (value === 4 || value === "4") {
                texts.push("肥胖");
              } else if (value === 0 || value === "0") {
                texts.push("无");
              }
              return texts;
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#02A4FF" },
                { offset: 1, color: "#0177FF" },
              ]),
            },
          },
        ],
      },
      cdata1: {
        grid: {
          top: 30, //距离容器上边界40像素
          left: 120,
          right: 0,
          bottom: 35,
        },
        xAxis: {
          type: "category",
          axisLine: false,
          axisLabel: {
            show: true,
            interval: 0,
            lineHeight: 14,
            color: "#000",
          },
          data: ["当前评估"],
        },
        yAxis: {
          axisLine: false,
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#f5f5f5"],
              width: 1,
              type: "solid",
            },
          },
          max: 3,
          min: 0,
          axisLabel: {
            color: "#000",
            formatter: function (value) {
              var texts = [];
              if (value === 1 || value === "1") {
                texts.push("腰围正常");
              } else if (value === 2 || value === "2") {
                texts.push("中心性肥胖1级");
              } else if (value === 3 || value === "3") {
                texts.push("中心性肥胖2级");
              } else if (value === 0 || value === "0") {
                texts.push("无");
              }
              return texts;
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#02A4FF" },
                { offset: 1, color: "#0177FF" },
              ]),
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        if (newValue) {
          this.listData = newValue;
          this.getData();
        }
      },
    },
    immediate: true,
    deep: true,
  },
  methods: {
    getData() {
      this.formatter();
    },
    chartToImg(val) {
      this.chartToImgUrl = val;
    },
    chartToImg1(val) {
      this.chartToImgUrl1 = val;
    },
    formatter() {
      const wholeBody = this.listData.extend.wholeBody; // 全身
      const center = this.listData.extend.center; // 中心

      if (wholeBody === "偏瘦") {
        this.wholeBodyNum = 1;
      } else if (wholeBody === "体重正常" || wholeBody === "正常") {
        this.wholeBodyNum = 2;
      } else if (wholeBody === "过重") {
        this.wholeBody = 3;
      } else if (wholeBody === "肥胖") {
        this.wholeBodyNum = 4;
      }
      if (center === "腰围正常") {
        this.centerNum = 1;
      } else if (center === "中心性肥胖1级") {
        this.centerNum = 2;
      } else if (center === "中心性肥胖2级") {
        this.centerNum = 3;
      }
      this.cdata.series[0].data = [this.wholeBodyNum];
      this.cdata1.series[0].data = [this.centerNum];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
