<template>
  <div>
    <div class="healthInformation">
      <div class="healthInformation-title">
        <div class="healthInformation-number stepNumber">6</div>
        <div class="fz20">
          高血压风险评估报告
          <div>
            <span class="smallTitle1">您高血压的发病风险为：</span>
            <span class="smallTitle2">{{ listData.riskNameType }}</span>
          </div>
        </div>
        <div></div>
      </div>
      <div class="healthInformation-table">
        <div class="chart" style="margin-bottom: 30px">
          <img :src="chartToImgUrl" class="chartImg" />
          <div class="display-none">
            <Chart :cdata="cdata" @chartToImg="chartToImg" />
          </div>
          <div class="text">
            <div cl>
              {{ listData.copywriting[0] || "" }}：
              <span class="smallTitle2">{{ listData.riskNameType }}</span>
            </div>
            <div>
              {{ listData.copywriting[1] || "" }}：
              <span class="smallTitle2">{{
                listData.riskReferenceNameType
              }}</span>
            </div>
            <div>{{ listData.copywriting[2] || "" }}</div>
          </div>
        </div>
        <div class="titleStyle">一、与高血压发病相关的危险因素</div>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td class="fz16">危险因素</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.riskFactor }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">本次检查 {{ parseTime(listData.theTime) }}</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.theValue }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">上次检查 {{ parseTime(listData.lastTime) }}</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.lastValue }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">变化情况</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.changeSituation }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">参考值</td>
            <td v-for="(item, index) in riskFactorList" :key="index">
              {{ item.referValue }}
            </td>
          </tr>
          <!-- 分段 -->
          <tr class="tableTitle">
            <td class="fz16">危险因素</td>
            <td v-for="(item, index) in riskFactorList2" :key="index">
              {{ item.riskFactor }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">本次检查 {{ parseTime(listData.theTime) }}</td>
            <td v-for="(item, index) in riskFactorList2" :key="index">
              {{ item.theValue }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">上次检查 {{ parseTime(listData.lastTime) }}</td>
            <td v-for="(item, index) in riskFactorList2" :key="index">
              {{ item.lastValue }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">变化情况</td>
            <td v-for="(item, index) in riskFactorList2" :key="index">
              {{ item.changeSituation }}
            </td>
          </tr>
          <tr class="tableTitle">
            <td class="fz16">参考值</td>
            <td v-for="(item, index) in riskFactorList2" :key="index">
              {{ item.referValue }}
            </td>
          </tr>
        </table>
        <div class="titleStyle">二、改善以下因素降低您的高血压发病风险</div>
        <table class="tableStyle tCenter">
          <tr class="tableTitle">
            <td v-for="(item, index) in improveSchemeArrList" :key="index">
              {{ item }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import { parseTime } from "@/utils/index";
import Chart from "@/components/echart/diseaseChart/chart";
export default {
  components: { Chart },
  mixins: [drawMixin],
  props: {
    listData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      parseTime,
      loading: true,
      riskFactorList: [],
      riskFactorList2: [],
      improveSchemeArrList: [],
      riskNameTypeNum: 0,
      riskReferenceNameTypeNum: 0,
      chartToImgUrl: "",
      cdata: {
        grid: {
          top: 30, //距离容器上边界40像素
          right: 10,
          left: 50,
          bottom: 35,
        },
        xAxis: {
          type: "category",
          axisLine: false,
          axisLabel: {
            show: true,
            interval: 0,
            lineHeight: 14,
            color: "#000",
          },
          data: ["当前风险", "理想风险"],
        },
        yAxis: {
          axisLine: false,
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#f5f5f5"],
              width: 1,
              type: "solid",
            },
          },
          max: 5,
          min: 0,
          axisLabel: {
            color: "#000",
            formatter: function (value) {
              var texts = [];
              if (value === 1 || value === "1") {
                texts.push("很低危");
              } else if (value === 2 || value === "2") {
                texts.push("低危");
              } else if (value === 3 || value === "3") {
                texts.push("中等");
              } else if (value === 4 || value === "5") {
                texts.push("高危");
              } else if (value === 5 || value === "5") {
                texts.push("很高危");
              } else if (value === 0 || value === "0") {
                texts.push("无");
              }
              return texts;
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 30,
            data: [],
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#02A4FF" },
                { offset: 1, color: "#0177FF" },
              ]),
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        if (newValue) {
          this.listData = newValue;
          this.getData();
        }
      },
    },
    immediate: true,
    deep: true,
  },
  methods: {
    getData() {
      this.riskFactorList = this.listData?.riskFactorsVOList;
      this.riskFactorList2 = this.riskFactorList.splice(
        Math.ceil(this.riskFactorList.length / 2)
      );
      this.improveSchemeArrList = this.listData?.improveSchemeArr;
      this.formatter();
    },
    chartToImg(val) {
      this.chartToImgUrl = val;
    },
    formatter() {
      const riskNameType = this.listData.riskNameType; // 当前风险
      const riskReferenceNameType = this.listData.riskReferenceNameType; // 理想风险

      if (riskNameType === "很低危") {
        this.riskNameTypeNum = 1;
      } else if (riskNameType === "低危") {
        this.riskNameTypeNum = 2;
      } else if (riskNameType === "中等") {
        this.riskNameTypeNum = 3;
      } else if (riskNameType === "高危") {
        this.riskNameTypeNum = 4;
      } else if (riskNameType === "很高危") {
        this.riskNameTypeNum = 5;
      }
      if (riskReferenceNameType === "很低危") {
        this.riskReferenceNameTypeNum = 1;
      } else if (riskReferenceNameType === "低危") {
        this.riskReferenceNameTypeNum = 2;
      } else if (riskReferenceNameType === "中等") {
        this.riskReferenceNameTypeNum = 3;
      } else if (riskReferenceNameType === "高危") {
        this.riskReferenceNameTypeNum = 4;
      } else if (riskReferenceNameType === "很高危") {
        this.riskReferenceNameTypeNum = 5;
      }

      this.cdata.series[0].data = [
        this.riskNameTypeNum,
        this.riskReferenceNameTypeNum,
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/report.scss";
</style>
